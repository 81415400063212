<template>
  <ul class="kt-menu__nav">
    <template v-for="(menu, i) in menuItems">
      <KTMenuItem v-if="!menu.section" :menu="menu" :key="i"></KTMenuItem>
      <KTMenuSection v-if="menu.section" :menu="menu" :key="i"></KTMenuSection>
    </template>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import KTMenuItem from "@/views/theme/aside/MenuItem.vue";
import KTMenuSection from "@/views/theme/aside/MenuSection.vue";
import menuConfig from "@/common/config/menu.config.json";

export default {
  name: "KTAsideMenu",
  data() {
    return {
      menuItems: Object
    };
  },
  components: {
    KTMenuItem,
    KTMenuSection
  },
  mounted() {
    this.menuItems  = this.getMenuItems();
  },
  methods: {
    getMenuItems() {
      var menuItems = menuConfig.aside.items;

      menuItems.forEach(item => {
        if(item.title == 'Dashboard') {
          if(this.currentUser.role == 'Admin') {
            //item.submenu.splice(1, 2);
            item.page = 'dashboard';
          } else {
            if(this.currentUser.role == 'Handler') {
              item.submenu.splice(0, 1);
              item.submenu.splice(1, 1);
              item.page = 'dashboard-handler';
            } else {
              if(this.currentUser.role == 'Surveyor') {
                item.submenu.splice(0, 2);
                item.page = 'dashboard-surveyor';
              }
            }
          }
        }
      });

      return menuItems;
    }
  },
  computed: {
    ...mapGetters([ "currentUser" ])
  }
};
</script>
